import { callRESTThrottled, getLocale, notCallGetWeather } from 'utils';
import { dispatcher, store } from 'store';
import slsConfig from 'sls-stack-output.json';
import ClientCache from 'components/ClientCache';

const SITE_NAME = slsConfig.TimestreamDB;

export const otherActions = {

  getQSDashboardUrl(dashboardId) {
    return function (dispatch) {
      return callRESTThrottled('POST', '/quickSight', {
        action: 'getDashboardURL',
        dashboardId,
      });
    };
  },
  markAsFavorite(params) {
    return function (dispatch) {
      return callRESTThrottled('POST', '/quickSight', {
        action: 'markAsFavorite',
        ...params,
      });
    };
  },
  getQSConsoleUrl() {
    return function (dispatch) {
      return callRESTThrottled('POST', '/quickSight', {
        action: 'getSessionURL',
      });
    };
  },
  listDashboards(sn) {
    return function (dispatch) {
      return callRESTThrottled('POST', '/quickSight', {
        action: 'listDashboards',
        sn,
      });
    };
  },
  getWeather() {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        if (notCallGetWeather) {
          resolve(false);
        } else {
          callRESTThrottled(
            'POST',
            '/config/v2/serve',
            {
              action: 'getWeather',
              lat: store.getState().site.siteMeta.GPSLat,
              lon: store.getState().site.siteMeta.GPSLong,
            }
          ).then((data) => {
            if (data.result.error) {
              resolve(false);
            } else {
              dispatch(dispatcher('UPDATE_SERVICE_MODULE', { weatherForcast: data.result }));
              resolve(true);
            }
          }).catch((err) => {
            resolve(false);
          });
        }
      });
    };
  },
  showComandsPopup(showState, popupPropsValue) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_SERVICE_MODULE', {
        popupProps: popupPropsValue,
        isShownComandsPopup: showState
      }));
    };
  },
  switchTheme(darkTheme) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_SERVICE_MODULE', { darkTheme }));
    };
  },
  getDataFromTimeStream(params) {
    return function (dispatch) {
      return callRESTThrottled('POST', '/historian', {
        action: 'getTableColumn',
        ...params,
        timeRange: {
          from: params.timeRange.from.endsWith('Z') ? params.timeRange.from :
            (params.timeRange.from + 'Z'),
          to: params.timeRange.to.endsWith('Z') ? params.timeRange.to : (params.timeRange.to + 'Z'),
        }
      });
    };
  },
  getTableColumnData(params) {
    return function (dispatch) {
      return new Promise(async (resolve, reject) => {
        let start = null;
        if (!params.nextToken && !params.queryId) {
          start = await callRESTThrottled('POST', '/historian', {
            action: 'startTableColumnQuery',
            ...params,
            timeRange: {
              from: params.timeRange.from.endsWith('Z') ? params.timeRange.from :
                (params.timeRange.from + 'Z'),
              to: params.timeRange.to.endsWith('Z') ? params.timeRange.to : (params.timeRange.to + 'Z'),
            }
          });
        }
        resolve({
          promise: new Promise((resolve, reject) => {
            const func = async () => {
              const end = await callRESTThrottled('POST', '/historian', {
                action: 'getTableColumnResult',
                type: 'data',
                queryId: start?.result.queryId || params.queryId,
                ...(params.nextToken ? { nextToken: params.nextToken } : {})
              });
              if (end.result.state === undefined) {
                if (end.result.rows && end.result.columns && end.result.columns[0] === 'TS') {
                  end.result.rows = end.result.rows.map(row => {
                    row[0] = row[0].endsWith('Z') ? row[0] : (row[0] + 'Z');
                    return row;
                  });
                }
                resolve({
                  ...end,
                  queryId: start?.result.queryId || params.queryId,
                });
              }
              else if (end.result.state === 'FAILED') reject(end);
              else setTimeout(func, 1000);
            };
            setTimeout(func, 1000);
          }),
          queryId: start?.result.queryId,
        });
      });
    };
  },

  getAlarmsHistory(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        console.log('calling getAlarmsHistory action', params.SN);
        callRESTThrottled('POST', '/historian', {
          action: 'getAlarmsHistory',
          table: 'Alarms_History',
          ...params
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  getFilterOptionsTimeStream() {
    let startTime, endTime;
    const fillInDescriptions = (arr, desc) => {
      return arr?.map(rec => {
        const returnRec = {};
        Object.keys(rec).forEach(key => {
          returnRec[key] = desc[rec[key]];
        });
        return returnRec;
      }) || [];
    };
    return function (dispatch) {
      startTime = new Date().getTime();

      const schema = 'action,category'; //Object.keys(data).toString()
      const db = new ClientCache(`${SITE_NAME}_FractalEMSData`, { historianConfig: schema });

      const hasCache = async (key) => {
        const results = await db.getHistorianData(key);
        if (results) return true;
        return false;
      };
      const getCache = async (key) => {
        const retData = await db.getHistorianData(key);
        return retData?.value;
      };
      const setCache = async (key, value) => {
        return await db.setHistorianData(key, value);
      };

      return new Promise((resolve, reject) => {
        console.log('cache begin', startTime);
        Promise.all([
          callRESTThrottled('POST', '/historian', {
            action: 'getOptions',
            category: 'Battery Data Table'
          }, true, true, hasCache, getCache, setCache), //last params are for dexie client side db
          callRESTThrottled('POST', '/historian', {
            action: 'getOptions',
            category: 'PCS Data Table'
          }, true, true, hasCache, getCache, setCache), //last params are for dexie client side db
          callRESTThrottled('POST', '/historian', {
            action: 'getOptions',
            category: 'Unit Data Table'
          }, true, true, hasCache, getCache, setCache), //last params are for dexie client side db
          callRESTThrottled('POST', '/historian', {
            action: 'getOptions',
            category: 'Rack Data Table'
          }, true, true, hasCache, getCache, setCache), //last params are for dexie client side db
          callRESTThrottled('POST', '/historian', {
            action: 'getOptions',
            category: 'Site Data Table'
          }, true, true, hasCache, getCache, setCache), //last params are for dexie client side db
        ])
          .then((res) => {
            console.log('cache result', new Date().getTime() - startTime);
            const final = [];
            let sn = 0;
            const allResults = res?.map(result => [...result.result]);
            if (allResults) {
              allResults.forEach(item => {
                const cat = Object.keys(item[0])[0];
                const catObject = {};
                const descriptions = item.pop().descriptions;
                item.forEach(record => {
                  const sn = Object.keys(record[cat])[0];
                  if (record[cat][sn].a)
                    catObject[sn] = {
                      columns: fillInDescriptions(record[cat][sn].columns, descriptions),
                      a: record[cat][sn].a
                    };
                  else
                    catObject[sn] = {
                      columns: fillInDescriptions(record[cat][sn].columns, descriptions)
                    };
                });
                sn += item.length;
                final.push({ [cat]: catObject });
              });
            }
            endTime = new Date().getTime();
            console.log('cache end', new Date().getTime() - startTime);
            console.log('Historian Data Elapsed Time', startTime, endTime, endTime - startTime);
            console.log(`Historian Returning ${sn} SNs`);
            const finalString = JSON.stringify(final);
            console.log('Historian finalString length', finalString.length);
            //localStorage.setItem('historian', finalString)

            resolve({ result: final });
          });
      });
    };
  },

  // return function (dispatch) {
  //   return new Promise((resolve) => {
  //     callRESTThrottled('POST', '/historian', {
  //       action: 'getOptions',
  //       //test: true,
  //       category: 'Battery Data Table'
  //     }).then((res) => {
  //       console.log('getFilterOptionsTimeStream', res);
  //       resolve(res);
  //     });
  //   });
  // };

  deleteSearch(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        console.log('calling saveSearches action');
        callRESTThrottled('POST', '/historian', {
          action: 'deleteSearch',
          ...params,
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  saveSearch(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        console.log('calling saveSearches action');
        callRESTThrottled('POST', '/historian', {
          action: 'saveSearch',
          ...params,
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  saveSearches(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        console.log('calling saveSearches action');
        callRESTThrottled('POST', '/historian', {
          action: 'saveSearches',
          ...params,
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  getSavedSearches(sn) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'getSavedSearches',
          sn,
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  getTableColumnCount(reqs) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        Promise.all(
          reqs.map(async params => {
            const start = await callRESTThrottled('POST', '/historian', {
              action: 'startTableColumnCountQuery',
              ...params,
              timeRange: {
                from: params.timeRange.from.endsWith('Z') ? params.timeRange.from :
                  (params.timeRange.from + 'Z'),
                to: params.timeRange.to.endsWith('Z') ? params.timeRange.to :
                  (params.timeRange.to + 'Z'),
              }
            });

            return new Promise((resolveInner, rejectInner) => {
              const func = async () => {
                const end = await callRESTThrottled('POST', '/historian', {
                  action: 'getTableColumnResult',
                  type: 'count',
                  queryId: start.result.queryId,
                });
                if (end.result.state === undefined) resolveInner(end);
                else if (end.result.state === 'FAILED') resolveInner(null);
                else setTimeout(func, 1000);
              };
              setTimeout(func, 1000);
            });
          })
        ).then((res) => {
          resolve(res);
        });
      });
    };
  },
  updateStore(data) {
    return function (dispatch) {
      Object.keys(data).forEach(module => {
        dispatch(dispatcher(`UPDATE_${module.toUpperCase()}_MODULE`, data[module]));
      });
    };
  },

  startExportHistorian(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'startExport',
          ...params,
          timeRange: {
            from: params.timeRange.from.endsWith('Z') ? params.timeRange.from :
              (params.timeRange.from + 'Z'),
            to: params.timeRange.to.endsWith('Z') ? params.timeRange.to : (params.timeRange.to + 'Z'),
          },
          timeMode: store.getState().user.timeMode,
          timezoneOffset: getLocale({
            timeMode: store.getState().user.timeMode,
            lat: store.getState().site.siteMeta.GPSLat,
            long: store.getState().site.siteMeta.GPSLong,
            utcOffset: true,
          })
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  getExportResultHistorian(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'getExportResult',
          ...params
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  startExportCountHistorian(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'startExportCount',
          ...params,
          timeRange: {
            from: params.timeRange.from.endsWith('Z') ? params.timeRange.from :
              (params.timeRange.from + 'Z'),
            to: params.timeRange.to.endsWith('Z') ? params.timeRange.to : (params.timeRange.to + 'Z'),
          }
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  getExportCountResultHistorian(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'getExportCountResult',
          ...params,
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  saveDisabledCategoryDataPointsHistorian(params) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'saveDisabledCategoryDataPoints',
          ...params,
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },

  getDisabledCategoryDataPointsHistorian() {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/historian', {
          action: 'getDisabledCategoryDataPoints',
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  setOptions(newValue) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/config/serve', {
          action: 'setGlobalSettings',
          newValue,
          table: 'Options',
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  getOptions() {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Options',
          extraParams: {
            'ProjectionExpression': 'options',
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':v': 'GLOBAL',
            },
            'KeyConditionExpression': 'SN = :v',
          },
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  changeUserData(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/user/changeUserData', {
          newData: data,
          username: store.getState().user.user.username,
        }).then(r => resolve(r)).catch(r => reject(r));
      });
    };
  },
};
