import moment from 'moment';
import React, { Suspense, useState, useEffect, useMemo } from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import slsConfig from 'sls-stack-output.json';

import { storeConnector } from 'store';

import { ReactComponent as CautionIcon } from 'assets/caution.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { ReactComponent as FireIcon } from 'assets/fire.svg';
import { ReactComponent as LoginIcon } from 'assets/login.svg';
import { ReactComponent as NoDataIcon } from 'assets/no-data.svg';

import PageNotFound from 'components/elements/PageNotFound/PageNotFound';
import UserPanel from 'components/UserPanel/UserPanel';
import Notifications from 'components/elements/Notifications/Notifications';
import SiteUnitComandsPopup from 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup';
import ChangePasswordPopup from 'components/ChangePasswordPopup';
import SupportPopup from 'components/SupportPopup';
import SettingsPopup from 'components/SettingsPopup';
import Clock from 'components/elements/clock/Clock';
import Input from 'components/elements/Input/Input';

import 'app.css';
import { CUSTOM_PAGE_URL } from 'components/CustomPages/constant';
import SystemAlarm from 'components/SystemAlarm';

const { FontAwesomeIcon } = React.lazy(() => import('@fortawesome/react-fontawesome'));

const Editor = React.lazy(() => import('components/Editor'));
const Login = React.lazy(() => import('components/Login/Login'));
const History = React.lazy(() => import('components/Home/History/History'));

const PasswordReset = React.lazy(() => import('components/PasswordReset'));
const MFASetup = React.lazy(() => import('components/MFASetup/MFASetup'));
const TotpRequired = React.lazy(() => import('components/TotpRequired'));
const VppSelect = React.lazy(() => import('components/Vpp/VppSelect/VppSelect'));
const ResetForgottenPassword = React.lazy(() => import('components/ResetForgottenPassword'));
const Home = React.lazy(() => import('components/Home/Home/Home'));

const Wind = React.lazy(() => import('components/Wind/Wind'));
const Sun = React.lazy(() => import('components/Sun/Sun'));
const MKT = React.lazy(() => import('components/Mkt/Mkt'));

const Battery = React.lazy(() => import('components/Battery/Battery'));
const DataTableView = React.lazy(() => import('components/Battery/Historian/DataTableView/DataTableView'));
const Admin = React.lazy(() => import('components/Admin/index'));

const CustomPages = React.lazy(() => import('components/CustomPages/CustomPages'));

const CHECK_AUTOLOGOUT_INTERVAL = 1000 * 3;

const App = (props) => {
  const { t } = useTranslation();
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [supportPopup, setSupportPopup] = useState(false);
  const [fastStopPopup, setFastStopPopup] = useState(false);
  const [, setLastActivity] = useState(null);
  const [changePasswdPopup, setChangePasswdPopup] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [version, setVersion] = useState(false);

  const htmlClassDuePath = (path) => {
    const pathArr = path.split('/');
    const firstLevelPath = ['battery', CUSTOM_PAGE_URL];
    const thirdLevelPath = ['alarm_and_warning', 'historian', 'calendar'];
    const fourthLevelPath = ['meter_and_status'];
    const onlyFirstLevelPath = ['historian-tableview'];
    const pathForHeightAutoOnSmallScreen = [''];
    if (
      [...firstLevelPath, ...onlyFirstLevelPath, ...pathForHeightAutoOnSmallScreen]
        .includes(pathArr[1])
    ) {
      if (
        thirdLevelPath.includes(pathArr[3]) ||
        onlyFirstLevelPath.includes(pathArr[1]) ||
        fourthLevelPath.includes(pathArr[4])
      ) {
        return 'auto-height';
      }
      if (pathForHeightAutoOnSmallScreen.includes(pathArr[1])) {
        return 'auto-height-small-screen';
      }
      return 'media-large';
    }
    return '';
  };

  const alarmState = () => {
    if (props.alarms) {
      const arr = Object.values(props.alarms).reduce((acc, cv) => {
        return [...acc, ...(Array.isArray(cv.Alarms) ? cv.Alarms : [])];
      }, []); const alarmWarn = arr.reduce(
        (acc, cv) => {
          if (cv['Active?'] && !cv.Acknowledged) {
            const severity = +cv.Severity;
            if (severity || severity === 0) {
              if (severity === 1 || severity > 2) {
                return [acc[0] + 1, acc[1], acc[2]];
              } else if (severity === 0) {
                return [acc[0], acc[1] + 1, acc[2]];
              } else if (severity === 2) {
                return [acc[0], acc[1], acc[2] + 1];
              }
            }
            return acc;
          }
          return acc;
        },
        [0, 0, 0]
      );
      return alarmWarn;
    } else {
      return [0, 0, 0];
    }
  };

  const activityHandler = (event) => {
    if (window.timerActivityHandler) {
      clearTimeout(window.timerActivityHandler);
    }
    if (props.user.username) {
      window.timerActivityHandler = window.setTimeout(() => {
        const currDate = moment().unix();
        localStorage.setItem('INACTIVITY_LOGOUT_DATE_PREV', currDate);
      }, CHECK_AUTOLOGOUT_INTERVAL);
    }
  };

  const setDefaultLogo = () => {
    const logo = document.querySelector('#project-logo');
    logo.src = require(`assets/logo${props.activeColor ? `_${props.activeColor}` : ''}.png`);
  };
  const sendFastStop = async () => {
    await props.actions.sendCommand(['WriteToVariant::FastStopSiteUI::1'],
      props.currentSite.SN);
    props.actions.notify('Command was sent');
    setFastStopPopup(false);
  };

  const handleChangeSiteDropdown = (val) => {
    const pathArr = props.currentPath.split('/');
    const siteSN = Object.keys(props.VPPsSitesMeta)[val];
    pathArr.splice(2, 1, siteSN);
    props.actions.clearCurrentSite();
    props.actions.getSiteMeta(siteSN);
    props.actions.getVPP();
    if (
      (pathArr[1] === 'sun' && !+props.VPPsSitesMeta[siteSN].NumPV) ||
      (pathArr[1] === 'wind' && !+props.VPPsSitesMeta[siteSN].NumWind) ||
      (pathArr[1] === 'mkt' && [0, '0'].includes(props.VPPsSitesMeta[siteSN].Mkt_Enable))
    ) {
      pathArr.splice(1, 1, 'home');
      pathArr.length = 3;
    }
    props.history.push(pathArr.join('/'));
  };

  const megaUnitDisabled = props.siteMeta
    && props.siteMeta.MW_Enable !== undefined
    && props.siteMeta.MW_Enable === 0;

  useEffect(() => {
    const init = async () => {
      document.body.removeAttribute('loading');
      document.getElementById('ems-preload-spinner-container').remove();
      setLastActivity((new Date()).getTime());
      props.actions.switchTheme(!(localStorage.getItem('lightTheme') === 'true'));
      localStorage.setItem('lightTheme', localStorage.getItem('lightTheme') === 'true');
      window.onresize = () => {
        window.scrollTo(0, 0);
      };
      document.querySelector('body').classList
        .add(`theme--${localStorage.getItem('lightTheme') === 'true' ? 'light' : 'dark'}${props.activeColor ? `-${props.activeColor}` : ''}`);
      await props.actions.initUser();
      document.onmousemove = e => activityHandler(e);
      document.ontouchmove = e => activityHandler(e);
      document.onclick = e => activityHandler(e);
      const pjson = require('../package.json');
      setVersion(pjson.version);
      const path = props.history.location.pathname;
      const htmlElem = document.getElementsByTagName('html')[0];
      htmlElem.setAttribute('class', htmlClassDuePath(path));
      const unlisten = props.history.listen(({ pathname, a, b }) => {
        const htmlElem = document.getElementsByTagName('html')[0];
        htmlElem.setAttribute('class', htmlClassDuePath(pathname));
      });
      if (props.user.group) {
        props.actions.getVPP();
      }
      if (props.siteMeta?.SiteName) {
        document.title = `Fractal EMS - ${props.siteMeta.SiteName}`;
      }
      return unlisten;
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (props.siteMeta?.SiteName) {
      document.title = `Fractal EMS - ${props.siteMeta.SiteName}`;
    }
    if (!props.siteMeta?.SiteName) {
      document.title = 'Fractal EMS';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.siteMeta?.SiteName]);
  const { currentPath } = props;

  const customPages = props.siteMeta?.Custom_Pages ?? [];
  const customPageRouteActive = currentPath.startsWith('/' + CUSTOM_PAGE_URL);
  const isSiteLossComm = useMemo(() => {
    if (!props.siteMeta
      || !props.currentSite
      || !props.currentSite.TS
      || !props.siteMeta.dataRateTimeoutSeconds
    ) {
      return true;
    }

    return moment().diff(moment(props.currentSite.TS), 'seconds') >
      +props.siteMeta.dataRateTimeoutSeconds;
  }, [
    props.siteMeta,
    props.currentSite,
  ]);

  return (
    <Router history={props.history}>
      <Suspense fallback={
        <div className='spinner-holder'>
          <div className='lds-ripple'><div /><div /></div>
        </div>
      }>
        <div className={'page'} style={{ scrollbarWidth: '50px' }}>
          <Notifications />
          {props.isShownComandsPopup && <SiteUnitComandsPopup />}
          <UserPanel showUserPanel={showUserPanel}
            close={() => setShowUserPanel(false)}
            openFastStopPopup={() => setFastStopPopup(true)}
            openChangePasswdPopup={() => setChangePasswdPopup(true)}
            openSettingsPopup={() => setSettingsPopup(true)}
            openSupportPopup={() => setSupportPopup(true)}
          />
          {changePasswdPopup &&
            <ChangePasswordPopup close={() => setChangePasswdPopup(false)} />
          }
          {settingsPopup &&
            <SettingsPopup close={() => setSettingsPopup(false)} />
          }
          {
            supportPopup &&
            <SupportPopup close={() => setSupportPopup(false)} />
          }
          {
            fastStopPopup &&
            <div className='cover-conteiner'>
              <div className='control-popup-container'>
                <div className='title-profile' style={{ paddingRight: '30px' }}>
                  {t('CONFIRM_EXECUTION_QUESTION')}
                </div>
                <div className='content-popup'>
                  <div style={{ margin: '20px' }}>{t('FAST_STOP')}</div>
                  <div className='btn-row'>
                    <button
                      onClick={() => sendFastStop()}
                      style={{ width: '100px' }}
                    >
                      {t('OK_UPPERCASE')}
                    </button>
                    <button
                      onClick={() => setFastStopPopup(false)}
                      style={{ width: '100px' }}
                    >
                      {t('CANCEL_UPPERCASE')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            (
              (props.loading && !props.loaders.login) ||
              (props.mfaConfigured === null && props.user.username)
            ) &&
            <div className='spinner-holder'>
              <div className='lds-ripple'><div /><div /></div>
            </div>
          }
          {props.connectionAlert &&
            <div className='alert-message red' onClick={() => props.actions.initUser()}>
              <div className='icon'>
                <FontAwesomeIcon icon='exclamation-circle' />
              </div>
              <div className='info'>
                <div className='title'>{t('CONNECTION_LOST')}</div>
                <div className='message'>{t('TRYING_TO_RECONNECT')}...</div>
              </div>
            </div>
          }
          {
            props.user.username &&
            <div className='bar'>
              <div className='version-label'>
                v{version}
              </div>

              <Link to='/' className='link' onClick={() => props.actions.clearCurrentSite()}>
                <img id='project-logo' src='/logos/customerLogo.png' alt=''
                  onError={() => setDefaultLogo()} />
              </Link>
              {
                props.siteMeta ?
                  <>
                    <div className='sndesc'>
                      <Input
                        type='dropdown'
                        value={{ value: props.siteMeta.SiteName }}
                        styleDD={{ zIndex: '101', marginBottom: '4px' }}
                        onChange={(val) => handleChangeSiteDropdown(val)}
                        options={
                          Object.keys(props.VPPsSitesMeta)
                            .map(siteSn => props.VPPsSitesMeta[siteSn].SiteName)
                        }
                      />
                      <div className='sn'>{props.siteMeta.Descriptor}</div>
                      <div className='descr'>
                        {props.siteMeta.kWMaxP / (megaUnitDisabled ? 1 : 1000)} {megaUnitDisabled ? 'kW' : 'MW'} /
                        {props.siteMeta.kWHRtg / (megaUnitDisabled ? 1 : 1000)} {megaUnitDisabled ? 'kWh' : 'MWh'}
                      </div>
                    </div>

                    <div className='items'>
                      <Link to={'/home/' + props.siteMeta.SN}
                        className={
                          'link ' + (props.currentPath.startsWith('/home') ? 'active' : '')}
                      >
                        <img
                          src={
                            require(`assets/home${currentPath.startsWith('/home') ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '_inactive'}.svg`)
                          }
                          className='icon'
                          alt=''
                          title={t('HOME')}
                        />
                      </Link>
                      <Link
                        to={`/battery/${props.siteMeta.SN}/operations_and_monitoring/sys/`}
                        className={
                          'link ' + (props.currentPath.startsWith('/battery') ? 'active' : '')}
                      >
                        <img
                          src={
                            require(`assets/battery${currentPath.startsWith('/battery') ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '_inactive'}.svg`)
                          }
                          className='icon'
                          alt=''
                          title={t('BATTERY')}
                        />
                      </Link>
                      {+props.siteMeta.NumPV ?
                        <Link to={`/sun/${props.siteMeta.SN}/operations_and_monitoring`}
                          className={
                            'link ' + (props.currentPath.startsWith('/sun') ? 'active' : '')}
                        >
                          <img
                            src={
                              require(`assets/sun${currentPath.startsWith('/sun') ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '_inactive'}.svg`)
                            }
                            className='icon'
                            alt=''
                            title={t('SUN')}
                          />
                        </Link>
                        :
                        <></>
                      }
                      {
                        +props.siteMeta.NumWind ?
                          <Link to={'/wind/' + props.siteMeta.SN}
                            className={
                              'link ' + (props.currentPath.startsWith('/wind') ? 'active' : '')}
                          >
                            <img
                              src={
                                require(`assets/wind-turbine${currentPath.startsWith('/wind') ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '_inactive'}.svg`)
                              }
                              className='icon'
                              alt=''
                              title={t('WIND')}
                            />
                          </Link>
                          :
                          <></>
                      }
                      <Link to={'/history/' + props.siteMeta.SN}
                        style={{ display: 'flex', alignItems: 'center' }}
                        className={
                          'link ' + (props.currentPath.startsWith('/history') ? 'active' : '')}
                      >
                        <img
                          src={
                            require(`assets/chart${currentPath.startsWith('/history') ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '_inactive'}.svg`)
                          }
                          className='icon'
                          alt=''
                          title={t('QUICKSIGHT')}
                        />
                      </Link>
                      {![0, '0'].includes(props.siteMeta.Mkt_Enable) ?
                        <Link to={`/mkt/${props.siteMeta.SN}/operations_and_monitoring`}
                          className={
                            'link ' + (props.currentPath.startsWith('/mkt') ? 'active' : '')}
                        >
                          <img
                            src={
                              require(`assets/dollar${currentPath.startsWith('/mkt') ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '-inactive'}.svg`)
                            }
                            className='icon dollar'
                            alt=''
                            title={t('MKT')}
                          />
                        </Link>
                        :
                        <></>
                      }
                      {customPages.length > 0 &&
                        <Link to={`/${CUSTOM_PAGE_URL}/${props.siteMeta.SN}/${customPages[0].Page}`}
                          className={
                            'link ' + (props.currentPath.startsWith(`/${CUSTOM_PAGE_URL}`) ? 'active' : '')}
                        >
                          <img
                            src={
                              require(`assets/custom-pages${customPageRouteActive ? `${props.activeColor ? `_${props.activeColor}` : '' }` : '_inactive'}.png`)
                            }
                            className='icon'
                            alt=''
                            title={t('CUSTOM_PAGES')}
                          />
                        </Link>
                      }
                    </div>
                  </>
                  :
                  <>
                    <div className='sndesc title'>
                      {(props.user.group === 'Administrator' &&
                        props.currentPath.startsWith('/admin')) ?
                        t('ADMIN_PANEL')
                        :
                        t('FLEET_MANAGER') + ' ' + (props.VPPs.Description ? ('– ' + props.VPPs.Description) : '')
                      }
                    </div>
                    <div className='items' />
                  </>

              }
              {
                props.user.group === 'Investor' ? <></> :
                  <div className='alerts'>
                    {props.siteMeta && props.currentSite && (
                      <div className={`alarm-icons ${props.user.group === 'Investor' && 'gs'}`}>
                        {isSiteLossComm && (
                          <NoDataIcon className='icon active' />
                        )}
                        {alarmState()[2] > 0 ? (
                          props.user.group === 'Investor' ?
                            <div className='icon-with-index'>
                              <FireIcon className='icon active' />
                              <div>{alarmState()[2]}</div>
                            </div>
                            :
                            <Link
                              to={`/battery/${props.siteMeta.SN}/alarm_and_warning`}
                            >
                              <div className='icon-with-index'>
                                <FireIcon className='icon active' />
                                <div>{alarmState()[2]}</div>
                              </div>

                            </Link>
                        ) : (
                          <FireIcon className='icon' />
                        )}
                        {alarmState()[0] > 0 ? (
                          props.user.group === 'Investor' ?
                            <div className='icon-with-index'>
                              <WarningIcon className='icon active' />
                              <div>{alarmState()[0]}</div>
                            </div>
                            :
                            <Link
                              to={`/battery/${props.siteMeta.SN}/alarm_and_warning`}
                            >
                              <div className='icon-with-index'>
                                <WarningIcon className='icon active' />
                                <div>{alarmState()[0]}</div>
                              </div>

                            </Link>
                        ) : (
                          <WarningIcon className='icon' />
                        )}
                        {alarmState()[1] > 0 ? (
                          props.user.group === 'Investor' ?
                            <div className='icon-with-index'>
                              <CautionIcon className='icon active' />
                              <div>{alarmState()[1]}</div>
                            </div>
                            :
                            <Link
                              to={`/battery/${props.siteMeta.SN}/alarm_and_warning`}
                            >
                              <div className='icon-with-index'>
                                <CautionIcon className='icon active' />
                                <div>{alarmState()[1]}</div>
                              </div>
                            </Link>
                        ) : (
                          <CautionIcon className='icon' />
                        )}
                      </div>
                    )}
                  </div>
              }

              {
                props.currentSite &&
                <Clock />
              }
              <div style={{ cursor: 'pointer', marginLeft: '12px' }}
                onClick={() => setShowUserPanel(true)}
              >
                <LoginIcon className='icon login' />
              </div>

            </div>
          }
          {
            props.userLoaded && (
              <Switch className='content'>
                <Route exact path='/login' render={() => <Login />} />
                <Route exact path='/forgotPassword' render={() => <ResetForgottenPassword />} />
                <Route exact path='/passwordReset' render={() => <PasswordReset />} />
                <Route exact path='/totpRequired' render={() => <TotpRequired />} />
                {
                  props.user.username && props.mfaConfigured !== null &&
                  [
                    ...(
                      (props.mfaConfigured !== false || slsConfig.MFA === 'OFF') ?
                        [
                          <Route key='/' exact path='/' render={() => <VppSelect />} />,
                          <Route key='/home/:sitesn' exact path='/home/:sitesn'
                            render={(p) => <Home key={p.match.params.sitesn} match={p.match} />}
                          />,
                          <Route key='/battery/:sitesn/*' exact path='/battery/:sitesn/*'
                            render={(p) => <Battery key={p.match.params.sitesn} match={p.match} />}
                          />,
                          <Route key='/sun/:sitesn/*' exact path='/sun/:sitesn/*'
                            render={(p) => <Sun key={p.match.params.sitesn} match={p.match} />}
                          />,
                          <Route key='/mkt/:sitesn/*' exact path='/mkt/:sitesn/*'
                            render={(p) => <MKT key={p.match.params.sitesn} match={p.match} />}
                          />,
                          <Route key='/wind/:sitesn' exact path='/wind/:sitesn'
                            render={(p) => <Wind key={p.match.params.sitesn} match={p.match} />}
                          />,
                          <Route key='/history/:sitesn' exact path='/history/:sitesn'
                            render={(p) => <History key={p.match.params.sitesn} match={p.match} />}
                          />,
                          <Route
                            key={`/${CUSTOM_PAGE_URL}/:sitesn/*`}
                            exact
                            path={`/${CUSTOM_PAGE_URL}/:sitesn/*`}
                            render={(p) =>
                              <CustomPages
                                key={p.match.params.sitesn}
                                match={p.match}
                              />
                            }
                          />,
                          <Route key='/config/' exact path='/config/' render={() => <Editor />} />,
                          <Route key='/config/:table' exact path='/config/:table'
                            render={(p) => <Editor match={p.match} />}
                          />,
                          <Route key='/historian-tableview/:request' exact
                            path='/historian-tableview/:request'
                            render={(p) => <DataTableView match={p.match} />}
                          />,
                          ...(
                            props.user.group === 'Administrator' ?
                              [<Route key='/admin' path='/admin' render={() => <Admin />} />]
                              : []
                          ),
                        ] :
                        [<Route key='/' path='/' render={() => <MFASetup />} />]
                    )
                  ]
                }
                <Route render={() => <PageNotFound />} />
              </Switch>
            )

          }
          <div className='powered-by'>
            <div className='powered'>{t('POWERED_BY')}:</div>
            <img src={require('assets/logo.png')} alt='' />
            <div className='company-name'>{t('FRACTAL_EMS_UPPERCASE')}</div>
          </div>
        </div>
        <SystemAlarm />
      </Suspense>
    </Router>
  );
};

export default storeConnector(App, {
  service: ['activeColor', 'history', 'darkTheme', 'loading', 'loaders', 'currentPath', 'isShownComandsPopup'],
  user: ['user', 'mfaConfigured', 'userLoaded'],
  vpp: ['VPPs', 'VPPsSitesMeta'],
  site: 'all',
  messages: ['alarms', 'connectionAlert'],
});
