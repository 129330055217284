import { createBrowserHistory } from 'history';

const initState = {
  activeColor: 'blue',
  darkTheme: true,
  history: createBrowserHistory(),
  currentPath: '',
  loading: false,
  loaders: {},
  subscriptions: {},
  weatherForcast: null,
  isShownComandsPopup: false,
  popupProps: null,
  tmpNotifications: [],
  updateFinished: false,
  currentInverterIdx: undefined,
};

export default (state = initState, action) => {
  const handlers = {
    'SUBSCRIBE_TO_EVENT': () => {
      const newState = {...state};
      if (!newState.subscriptions[action.source]) {
        newState.subscriptions[action.source] = [];
      }
      newState.subscriptions[action.source].push(action.handler);
      return newState;
    },
    'UNSUBSCRIBE_FROM_EVENT': () => {
      const newState = {...state};
      if (!newState.subscriptions[action.source]) {
        newState.subscriptions[action.source] = [];
      }
      const idx = newState.subscriptions[action.source].indexOf(action.handler);
      if (idx) {
        newState.subscriptions[action.source].splice(idx, 1);
      }
      return newState;
    },
    'LOADING': () => {
      const newState = {...state};
      delete action.type;
      const loaderName = Object.keys(action)[0];
      if (action[loaderName]) {
        newState.loaders[loaderName] = true;
      } else {
        delete state.loaders[loaderName];
      }
      if (Object.keys(state.loaders).length) {
        newState.loading = true;
      } else {
        newState.loading = false;
      }
      return newState;
    },
    'SET_CURRENT_PATH': () => {
      return {...state, currentPath: state.history.location.pathname};
    },
    'UPDATE_SERVICE_MODULE': () => {
      delete action.type;
      return {...state, ...action};
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};